import Vue from "vue";
import VueRouter from "vue-router";
import { store } from "@/store";
Vue.use(VueRouter);
function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

export default function createRouter(props = {}) {
  const routes = [
    {
      path: "/",
      component: () =>
        import(/* webpackChunkName: "routed-index" */ "@/views/Home/Home.vue"),
      props: route => ({
        ...props,
        apiKey: route.query.apiKey || store?.state?.apiKey
      })
    },
    {
      path: "/pois",
      component: () =>
        import(/* webpackChunkName: "routed-main" */ "@/views/List/List.vue"),
      props: route => ({
        ...props,
        apiKey: route.query.apiKey || store?.state?.apiKey
      })
    },
    {
      path: "/teaser",
      component: () =>
        import(
          /* webpackChunkName: "routed-teaser" */ "@/views/Teaser/Teaser.vue"
        ),
      props: route => ({
        ...props,
        apiKey: route.query.apiKey || store?.state?.apiKey
      })
    },
    {
      path: "/pois/:poiId",
      component: () =>
        import(
          /* webpackChunkName: "routed-main" */ "@/views/DetailView/DetailView.vue"
        ),
      props: route => ({
        id: props.id,
        poiId: route.params.poiId,
        apiKey: route.query.apiKey || store?.state?.apiKey
      })
    },
    {
      path: "/summary",
      component: () =>
        import(
          /* webpackChunkName: "routed-summary" */ "../views/Summary/Summary.vue"
        ),
      props: route => ({
        ...props,
        apiKey: route.query.apiKey || store?.state?.apiKey
      })
    }
  ];
  const Router = new VueRouter({
    mode: "history",
    base: "/v1/",
    routes
  });

  Router.beforeEach((to, from, next) => {
    if (!hasQueryParams(to) && hasQueryParams(from)) {
      next({ ...to, query: { ...to.query, ...from.query } });
    } else {
      next();
    }
  });
  return Router;
}
