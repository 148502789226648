import App from "@/App.vue";
import Vue from "vue";
import Vuetify from "vuetify/lib";
import appStore from "@/store";
import de from "vuetify/es5/locale/de";
import en from "vuetify/es5/locale/en";
import "@mdi/font/css/materialdesignicons.css";
import {
  getTheme,
  icons,
  getAttributeValue,
  getDefaultStoreValues,
  getProps
} from "@/helpers/entriesHelper";
import createRouter from "@/router";
import { opChain } from "@/mixins/globalMethods";
import axios from "axios";
import { setupCache } from "axios-cache-adapter";

const cacheMaxAge = 300;
axios.defaults.headers["Cache-Control"] = `max-age=${cacheMaxAge}`;
axios.defaults.cache = setupCache({
  maxAge: cacheMaxAge * 1000
});

Vue.config.productionTip = false;
Vue.mixin({
  methods: {
    opChain
  }
});
Vue.use(Vuetify);

const elements = [...document.getElementsByClassName("eopoi-widget")];
elements.forEach(element => {
  const locale = getAttributeValue(element, "data-locale") || "de-de";
  const vuetify = new Vuetify({
    icons,
    theme: getTheme(element),
    lang: {
      locales: { de, en },
      current: locale
    }
  });

  const namespace = getAttributeValue(element, "data-namespace") || "eopoi";
  const store = appStore(namespace, getDefaultStoreValues(element));

  const router = createRouter({
    ...store.state,
    locale
  });

  new Vue({
    vuetify,
    store,
    router,
    render: function (h) {
      return h(App, {
        props: getProps(element, "eopoi-widget")
      });
    }
  }).$mount(element);
});
